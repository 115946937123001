import React, { useState, useContext, useEffect } from 'react';
import { useHistory, NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import ApxDialog from 'comps/ApxDialog';
import { AppContext } from 'AppContext';
import * as QueryString from "query-string";

const SignIn = (props) => {

    const { config, setIdentity } = useContext(AppContext);
    const [history] = useState(useHistory());

    const params = QueryString.parse(props.location.search);
    const [username, setUsername] = useState(params.username ? params.username : "");
    const [password, setPassword] = useState("");

    const [dialogState, setDialogState] = useState({ show: false })
    const [state, setState] = useState({ busy: false, event: "ready", data: {}})

    useEffect(()=>{
        switch(state.event) {
            case "submit":
                onEnterStateSubmit(state.data);
                break;
            case "redirect":
                onEnterStateRedirect(state.data);
                break;
            case "error" :
                onEnterStateError(state.data);
                break;
        }

    }, [state])

    function onEnterStateSubmit(context){
        const action = async () => {
            try {
                const fields = {
                    username: username,
                    password: password
                }
                const response = await axios.post(config.api.base + '/login', {...fields})
                if(response.status === 201) {
                    console.log(response.data);
                    setIdentity(response.data)

                    let redirectPath = '/dashboard';
                    if(response.data.userAdmin === true) {
                        redirectPath = '/admin/dashboard';
                    }

                    if (props.hasOwnProperty('location') && props.location != undefined) {
                        if (props.location.hasOwnProperty('state') && props.location.state != undefined) {
                            if (props.location.state.hasOwnProperty('from') && props.location.state.from != undefined) {
                                redirectPath = props.location.state.from;
                            }
                        }
                    }

                    setState({ busy: false, event: "redirect", data: { path: redirectPath, id: response.data }})
                }

            } catch (x) {
                setState({ busy: false, event: "error", data: { text: x.response.data.message }})
            }
        }
        action();
    }

    function onEnterStateRedirect(context) {
        setIdentity(context.id);
        history.push(context.path);
    }

    function onEnterStateError(context) {
        setDialogState({ show: true, message: context.text})
    }

    function onChangeUsername(e) {
        setUsername(e.target.value);
    }

    function onChangePassword(e) {
        setPassword(e.target.value);
    }


    function onSubmit(e) {
        setState({ busy: true, event: "submit", data: {}})
        e.preventDefault();
    }

    function onDialogOkay() {
        setDialogState({ show: false, message: ""})
    }

    return(
        <div className="min-h-screen flex justify-center bg-gray-50 pt-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Sign in to your account
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Or&nbsp;
                        <NavLink to='/sign-up' className="font-medium text-indigo-600 hover:text-indigo-500">
                            click here to create an account
                        </NavLink>
                    </p>
                </div>
                <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">Email address</label>
                            <input id="email-address" name="email" type="email" autoComplete="email" required
                                   value={username}
                                   onChange={onChangeUsername}
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="Email address"/>
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input id="password" name="password" type="password" autoComplete="current-password"
                                   required
                                   value={password}
                                   onChange={onChangePassword}
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="Password"/>
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input id="remember_me" name="remember_me" type="checkbox"
                                   className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"/>
                            <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                                Remember me
                            </label>
                        </div>
                        <div className="text-sm">
                            <NavLink to='/reset-password' className="font-medium text-indigo-600 hover:text-indigo-500">
                                Forgot your password?
                            </NavLink>
                        </div>
                    </div>
                    <div>
                        <button className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
            <ApxDialog show={dialogState.show} onOkay={onDialogOkay} message={dialogState.message}></ApxDialog>
        </div>
    )
}

export default SignIn;